/* eslint-disable @typescript-eslint/no-unused-vars */
import { createFeature, createReducer, on } from '@ngrx/store';
import { IAuth } from '@sybl/feature-auth-models';
import { signalStore, withState, withMethods, withHooks } from '@ngrx/signals';
import { withDevtools } from '@angular-architects/ngrx-toolkit';
//import { environment } from '@frontend/env';
import { PermissionsEnum } from '@sybl/feature-permissions-models';
import { withAuthStatusMethods } from '../selectors';
import { ISideNavUrlLink } from '@sybl/common-models';
import { withEntities } from '@ngrx/signals/entities';
//TODO figure out how to use environment variables in reducers...
export const AUTH_STATUS_KEY = 'status';

export interface AuthStatusState {
  avatarUrl: string;
  defaultAvatar: string;
  registeredAvatar: string;
  avatar: string;
  avatarUndefined: boolean;
  errors: string;
  user: IAuth | any;
  user_id: string | undefined;
  permissions: PermissionsEnum;
  email: string;
  username: string;
  jwtToken: string;
  tenant: string;
  authAttempts: number;
  loggedIn: boolean;
  loading: boolean;
  inTimeOut: boolean;
  inTimeOutPending: boolean;
  timeOutSeconds: number;
  lockUntil: number;
  emailSubmitted: boolean;
  wrongPassword: boolean;
  // SignUp may separate
  password: string;
  confirmPass: string;
  returnToUrl: string;
  leftSideLinks: Array<ISideNavUrlLink>;
  leftSideBottomLinks: Array<ISideNavUrlLink>;
  rightSideLinks: Array<ISideNavUrlLink>;
  toolbarLinks: Array<ISideNavUrlLink>;
  //
}

export const initialState: AuthStatusState = {
  avatarUrl: '',
  defaultAvatar:
    'https://sybl-site.s3.us-east-1.amazonaws.com/avatars/default.svg',
  registeredAvatar: '',
  avatar: undefined,
  tenant: undefined,
  avatarUndefined: true,
  errors: '',
  user_id: undefined,
  user: undefined,
  permissions: PermissionsEnum['guest'],
  email: 'guest',
  username: 'guest',
  jwtToken: '',
  authAttempts: 0,
  loggedIn: false,
  loading: false,
  inTimeOut: false,
  inTimeOutPending: false,
  timeOutSeconds: 0,
  lockUntil: 0,
  emailSubmitted: false,
  wrongPassword: false,
  password: null,
  returnToUrl: null,
  confirmPass: null,
  toolbarLinks: [
    {
      url: '/about/home',
      displayName: 'About',
      description: 'About Page',
      visible: true,
      syblAdmin: false,
      iconName: 'about-icon.svg',
    },
  ],
  leftSideLinks: [
    // Not Using These Once Logged In
    /**
     {
     url:'/home',
     displayName: 'Home',
     description: 'Home Page',
     visible: true,
     iconName:  'venue.svg'
     },
     {
     url:'/about/home',
     displayName: 'About',
     description: 'About Page',
     visible: true,
     iconName:  'about-icon.svg'
     },*/
    {
      url: '/co-pilot/chat',
      displayName: 'Ask LinqCo-Pilot',
      description: 'Ask LinqCo-Pilot',
      syblUser: true,
      visible: true,
      syblAdmin: false,

      iconName: 'sybil-bot.svg',
    },
    {
      url: 'zenDesk',
      displayName: 'Training and Support',
      description: 'Training and Support',
      visible: true,
      syblAdmin: false,
      iconName: 'faq.svg',
    },
    {
      url: '/co-pilot/account-management/seats',
      displayName: 'Manage Users',
      description: 'Manage Users',
      syblAdmin: true,
      visible: true,
      syblTeam: true,
      iconName: 'employees_min.svg',
    },
    {
      url: 'https://billing.stripe.com/p/login/9AQ5lw2Il3Lz8245kk',
      displayName: 'Self Service Portal',
      description: 'Self Service Portal',
      visible: true,
      syblAdmin: true,
      syblTeam: true,
      syblUser: false,
      iconName: 'new-bucket_self-service-portal.svg',
    },
  ],

  leftSideBottomLinks: [
    {
      url: '/co-pilot/account-management/agency-info',
      displayName: 'Account Settings',
      description: 'Account Settings',
      syblAdmin: true,
      visible: true,
      iconName: 'settings.svg',
    },
    /* {
       url: '/sybl/release-notes',
       displayName: 'Release Notes',
       description: 'Release Notes',
       syblAdmin: false,
       visible: true,
       iconName: 'note-icon.svg',
     },
     */
  ],
  rightSideLinks: [
    {
      url: 'logout',
      displayName: 'Logout',
      description: 'Click to Logout',
      visible: true,
      permission: 0,
      iconName: 'logout.svg',
    },
    {
      url: '/user/edit-profile/info',
      displayName: 'Profile Settings',
      description: 'Click for Profile Settings',
      visible: true,
      permission: 0,
      iconName: 'settings.svg',
    },
    /*{
      url: '/images/pdf-upload',
      displayName: 'PDF Upload',
      description: 'Upload PDF',
      visible: true,
      permission: 3,
      iconName: 'pdf_upload.svg',
    }
    }
    {
        url: '/user/search-logs',
        displayName: 'User Files',
        description: 'Files user has access to',
        visible: true,
        permission: 3,
        iconName: 'files-icon.svg'
    },
    */
    {
      url: '/admin/home',
      displayName: 'Admin Page',
      description: 'Admin Page',
      visible: true,
      permission: 7,
      iconName: 'admin-icon.svg',
    },
    {
      url: '/mailer',
      displayName: 'Mailer',
      description: 'Mailer',
      visible: true,
      permission: 7,
      iconName: 'campaign.svg',
    },
  ],
  /*
    iconName:  'standup_mic.svg'
    {    url: '/tv/home',
        displayName: 'TV',
        description: 'TV',
        visible: true,
        iconName:  'tv.svg'
    },
    {    url: '/movies/home',
        displayName: 'Movies',
        description: 'Movies',
        visible: true,
        iconName:  'film.svg'
    },
    {    url: '/woke-wars/home',
        displayName: 'Woke Wars',
        description: 'Woke Wars ',
        visible: true,
        iconName:  'megaphone.svg'
    },
    {
        url:'/books/home',
        displayName: 'Library',
        description: 'Library of Books for or By Comedians',
        visible: true,
        iconName:  'book.svg'
    },
    */
};

export const AuthStatusStore = signalStore(
  { providedIn: 'root' },
  // withEntities(),
  withState(initialState),
  withAuthStatusMethods(),
  withHooks({
    onInit(store) {
      //if (store.loadFromLocalStorage()) {
      // return;
      // }
      // store.load(1);
    },
  }),
);

export const authStatusFeature = createFeature({
  name: 'auth-status',
  reducer: createReducer(
    initialState
  ),
});

export const getLoggedIn = (state: AuthStatusState) => state.loggedIn;
export const getLoading = (state: AuthStatusState) => state.loading;
export const getEmail = (state: AuthStatusState) => state.email;
export const getUser = (state: AuthStatusState) => state.user;
export const getUserId = (state: AuthStatusState) => state.user_id;
export const getUsername = (state: AuthStatusState) => state.username;
export const getJWTToken = (state: AuthStatusState) => state.jwtToken;
export const getPermissions = (state: AuthStatusState) => state.permissions;
export const tenant = (state: AuthStatusState) => state.tenant;
export const getAvatar = (state: AuthStatusState) => state.avatar;
export const getAvatarUndefined = (state: AuthStatusState) =>
  state.avatarUndefined;

export const getWrongPassword = (state: AuthStatusState) => state.wrongPassword;
export const getAuthAttempts = (state: AuthStatusState) => state.authAttempts;
export const getInTimeOut = (state: AuthStatusState) => state.inTimeOut;
export const getEmailSubmitted = (state: AuthStatusState) =>
  state.emailSubmitted;
export const getLockUntil = (state: AuthStatusState) => state.lockUntil;
export const getLoginAttempts = (state: AuthStatusState) => state.authAttempts;
export const getTimeOutSeconds = (state: AuthStatusState) =>
  state.timeOutSeconds;
